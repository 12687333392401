/**
  * @author Kevin Lopez.
  * Friday, 28 April 2023 03:19 AM
  * @description process textarea content and parse to insert into database
*/

function clearNameAnalogic(tagName, validComments) {
  let lastComment = ''
  let description
  // eslint-disable-next-line prefer-destructuring
  lastComment = validComments.slice(-1)[0]
  const newName = tagName.split('.')[0]
  if (lastComment.includes(newName)) {
    const info = lastComment.replace(/-|=|\//g, '')
    description = info.split(newName)[1].trim()
  }
  return { name: newName, description }
}

function filterLine(string) {
  const text = string.trim()
  return (
    !text.startsWith('/')
        && !text.startsWith(':')
        && !text.includes('Underrange')
        && !text.includes('UnderRange')
        && !text.includes('OutOfRange')
        && !text.includes('OpenWire')
        && !text.includes('WireOffReset')
        && !text.includes('OutputEnable')
        && !text.includes('RESERVA')
        && !text.includes('Reserva')
        && text.includes('AR')) ? text : ''
}

function filterComment(string) {
  const textFiltered = (
    string.startsWith('/')
        && !string.includes('Reserva')
        && !string.includes('reserva')) ? string : ''
  const regExpression = new RegExp('[a-zA-Z]+')
  return regExpression.exec(textFiltered) !== null ? textFiltered : ''
}

function parseLine(str) {
  if (typeof str !== 'string') {
    throw new TypeError('Argument should be string')
  }
  try {
    const content = str.toString().replace(/\t/g, '')
    const strArr = content.split('//')
    const description = strArr.length > 1 ? strArr.slice(-1)[0] : ''
    const located = strArr[0].split(':=')

    let position1 = located[0]
    position1 = position1.replace(/ /g, '')
    position1 = position1.replace(/;/g, '')

    let position2 = located.slice(-1)[0]
    position2 = position2.replace(/ /g, '')
    position2 = position2.replace(/;/g, '')

    let channel = ''
    let tagName = ''
    if (position1.startsWith('AR') || position1.includes(':')) {
      // eslint-disable-next-line prefer-destructuring
      channel = position1.split(';')[0]
      tagName = position2
    }

    if (position2.startsWith('AR') || position2.includes(':')) {
      // eslint-disable-next-line prefer-destructuring
      channel = position2.split(';')[0]
      tagName = position1
    }
    if (channel.toUpperCase().startsWith('NOT')) {
      channel = channel.slice(3)
    }
    if (tagName.length <= 1) return 0

    // Parse para analogicas y obtener solo el tag
    if (tagName.includes('.')) {
      // eslint-disable-next-line prefer-destructuring
      tagName = tagName.split('.')[0]
    }
    return {
      description: null,
      comment: description,
      tagName,
      location: channel,
    }
  } catch (error) {
    console.error('Ha ocurrido un error al obtener la información del texto.', str, error)
    return 0
  }
}

// Obtiene el string con la info del canal y devuelve la informacion en un obj
function extractLocationInfo(channel) {
  if (typeof channel !== 'string') {
    throw new TypeError('Argument should be string')
  }
  try {
    const parseUbicacion = channel.split(':')
    let parseChannel = parseUbicacion.slice(-1)[0]
    parseChannel = parseChannel.split('.')
    const armarioName = parseUbicacion[0]
    let slot = parseUbicacion[1]
    const tipo = parseChannel[0]
    let canal = parseChannel.slice(-1)[0]
    if (canal.includes('Ch')) {
      const inicio = canal.indexOf('Ch') + 2
      const fin = canal.indexOf('Data')
      canal = canal.substring(inicio, fin)
    }
    if (slot.includes('[') && slot.includes(']')) {
      const i = slot.indexOf('[')
      slot = slot.substring(i + 1, i + 2)
    }
    return {
      armario: armarioName,
      slot,
      tipo,
      canal,
    }
  } catch (error) {
    console.error('Error al extraer la informacion de la locacion del tag.', channel, error)
    return {}
  }
}

function processTextArea(content) {
  // const content = document.getElementById('elencofile')?.value
  const withoutSpaces = content.split('\n')
  withoutSpaces.forEach(codeLine => {
    const xmlSim = { _cdata: codeLine }
    const line = cleanXMLLine(xmlSim)
    console.log(line)
    if (line) {
      // line.description = tagsArr[line.tagName] ? tagsArr[line.tagName] : ''
      line.armario = line.location?.armario
      line.slot = line.location?.slot
      line.tipo = line.location?.tipo
      line.canal = line.location?.canal
      line.PLC = 'plcName'
      delete line.location
      console.log(line)
    }
  })
  return
  const result = {}
  const validComments = [] // New
  const row = []
  // Table
  // const table = document.getElementById('elenco_tbl_body')

  withoutSpaces.forEach(codeLine => {
    if (codeLine.startsWith('/')) {
      const comment = filterComment(codeLine)
      // eslint-disable-next-line no-unused-expressions
      comment !== '' ? validComments.push(comment) : ''
      return
    }
    const lineFiltered = filterLine(codeLine)
    if (!lineFiltered) {
      return
    }
    const channelObj = parseLine(lineFiltered)
    const channelInfo = extractLocationInfo(channelObj.location)
    channelObj.routes = channelInfo

    let analogicParsed = null
    if (channelObj.tagName.includes('_PV') || channelObj.tagName.includes('_CV')) {
      analogicParsed = clearNameAnalogic(channelObj.tagName, validComments)
    }
    // Return si no hay armario
    if (!channelObj.routes.armario) {
      return
    }

    const tag = analogicParsed ? analogicParsed.name : channelObj.tagName
    const desc = analogicParsed ? analogicParsed.description : channelObj.description
    row.push({
      tag,
      desc,
      armario: channelObj.routes.armario,
      slot: channelObj.routes.slot,
      tipo: channelObj.routes.tipo,
      canal: channelObj.routes.canal,
    })
    if (!result[channelObj.routes.armario]) {
      result[channelObj.routes.armario] = {}
    }
    if (!result[channelObj.routes.armario][channelObj.routes.slot]) {
      result[channelObj.routes.armario][channelObj.routes.slot] = {}
    }
    result[channelObj.routes.armario][channelObj.routes.slot][channelObj.routes.canal] = { tag: '', desc: '', tipo: '' }
    result[channelObj.routes.armario][channelObj.routes.slot][channelObj.routes.canal].tag = tag !== undefined ? tag : ''
    result[channelObj.routes.armario][channelObj.routes.slot][channelObj.routes.canal].desc = desc !== undefined ? desc : ''
    result[channelObj.routes.armario][channelObj.routes.slot][channelObj.routes.canal].tipo = channelObj.routes.tipo
  })

  return { result, row }
}

// Funciones para procesar documentos XML

// Obtiene los tags con descripcion
function listTagsFromXML(tags) {
  return new Promise((resolve, reject) => {
    try {
      const result = {}
      if (tags) {
        for (let index = 0; index < tags.length; index += 1) {
          // eslint-disable-next-line no-underscore-dangle
          const name = tags[index]._attributes.Name
          if (tags[index].Description) {
            // eslint-disable-next-line no-underscore-dangle
            result[name] = tags[index].Description._cdata
          }
        }
        resolve(result)
      } else {
        reject(new Error('La lista de tags está vacía o es nula.'))
      }
    } catch (error) {
      reject(new Error('No se puede procesar los tags del XML', error))
    }
  })
}

function cleanXMLLine(line) {
  // eslint-disable-next-line no-underscore-dangle
  if (!line._cdata) {
    return 0
  }
  // eslint-disable-next-line no-underscore-dangle
  const texto = String(line._cdata)
  const isCode = !texto.startsWith('/') && !texto.startsWith('#') && !texto.includes('(') && !texto.includes(')') && texto !== 'undefined'
  const lineArr = texto.split(';')
  const isChannel = lineArr[0].includes('Ch') || lineArr[0].includes('Data') || lineArr[0].includes('AR')
  const isFbkBad = !texto.includes('Inp_PVBad') && !texto.includes('Inp_IOFault') && !texto.includes('Underrange') && !texto.includes('OpenWire')
  if (isCode && isChannel && isFbkBad) {
    const result = parseLine(texto)
    if (result?.location) {
      result.location = extractLocationInfo(result.location)
    }
    return result
  }
  return 0
}

async function XMLElenco(dataJSON) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      if (dataJSON) {
        const programTags = {}
        const tagsArr = await listTagsFromXML(dataJSON.RSLogix5000Content.Controller.Tags.Tag)
        const plcName = dataJSON.RSLogix5000Content.Controller._attributes.Name
        const programas = dataJSON.RSLogix5000Content.Controller.Programs.Program.Routines.Routine
        for (let index = 0; index < programas.length; index += 1) {
          const Lines = programas[index].STContent?.Line
          if (Lines.length) {
            for (let j = 0; j < Lines.length; j += 1) {
              const line = cleanXMLLine(Lines[j])
              if (line) {
                line.description = tagsArr[line.tagName] ? tagsArr[line.tagName] : ''
                line.armario = line.location?.armario
                line.slot = line.location?.slot
                line.tipo = line.location?.tipo
                line.canal = line.location?.canal
                line.PLC = plcName
                delete line.location
                programTags[line.tagName] = line
              }
            }
          }
        }
        resolve(programTags)
      } else {
        reject(new Error('El objeto dataJSON está vacío o es nulo.'))
      }
    } catch (error) {
      reject(new Error('No se puede procesar los programas del XML', error))
    }
  })
}

export default {
  processTextArea,
  listTagsFromXML,
  cleanXMLLine,
  XMLElenco,
}
