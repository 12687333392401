<template>
  <div>
    <form id="csvForm">
      <b-form-textarea
        v-if="false"
        id="textarea"
        v-model="text"
        placeholder="Ingrese la rutina..."
        rows="8"
        max-rows="16"
        name="elencoraw"
        class="mb-1"
      />
      <input
        id="csvFile"
        type="file"
        accept=".L5X"
        @change="process"
      >
      <b-button
        variant="primary"
        :disabled="!fileContent"
        @click="processTextBox"
      >
        Procesar
      </b-button>
    </form>
  </div>
</template>

<script>
import {
  BFormTextarea, BButton, BRow, BCol, BCard,
} from 'bootstrap-vue'
import convert from 'xml-js'
import util from './util'
import repository from '@/../apis/Calibraciones'

export default {
  components: {
    BFormTextarea, BButton, BRow, BCol, BCard,
  },
  data() {
    return {
      text: '',
      file: null,
      plcList: null,
      fileContent: null,
    }
  },
  async mounted() {
    this.plcList = await repository.actions.getPLCList()
  },
  methods: {
    processTextBox() {
      // util.processTextArea(this.text)
      this.xmlToArray(this.fileContent)
    },
    process(e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) { return }
      const input = files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.fileContent = e.target.result
      }
      reader.readAsText(input, 'ISO-8859-1')
    },
    csvToArray(str, delimiter = ',') {
      const headers = str.slice(0, str.indexOf('\n')).split(delimiter)
      const rows = str.slice(str.indexOf('\n') + 1).split('\n')
      const arr = rows.map(row => {
        const values = row.split(delimiter)
        const el = headers.reduce((object, header, index) => {
          object[header] = values[index]
          return object
        }, {})
        return el
      })
      return arr
    },
    async xmlToArray(xml) {
      const result = convert.xml2json(xml, { compact: true })
      const dataJSON = JSON.parse(result)
      const pagination = []
      let page = 0
      if (dataJSON) {
        const items = await util.XMLElenco(dataJSON)
        pagination[page] = []
        Object.keys(items).forEach((value, index) => {
          if (((index / 100) % 1) === 0 && index !== 0) {
            page += 1
            pagination[page] = []
          }
          pagination[page].push(items[value])
        })

        // eslint-disable-next-line no-restricted-syntax
        for (const item of pagination) {
          if (item.length) {
            // eslint-disable-next-line no-underscore-dangle, no-await-in-loop
            await repository.actions.postFileTags(item, dataJSON.RSLogix5000Content.Controller._attributes.Name)
          }
        }

        this.$bvToast.toast('Archivo procesado', {
          title: 'Correcto...',
          variant: 'success',
          solid: true,
        })
      }
    },
  },
}
</script>

<style lang="scss">
  .item{
    &:hover, &.active {
      background-color: #FE6F17 !important;
      color: white;
      border-radius: 8px;
      font-weight: bold;
      cursor: pointer;
    }
    padding: 8px;
  }
  .limit-h{
    max-height: 80vh;
    overflow: scroll;
  }
</style>
